.promo {
  width: 100%;
  min-height: 650px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: $rhodeisland-white;

  a {
    font-weight: 700;
  }

  &.overlay {
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .grid-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    h2 {
      color: $rhodeisland-gold;
      font-size: 50px;
      letter-spacing: 0.04em;
      line-height: 46px;
      margin-bottom: 0;

      @include breakpoint(small) {
        font-size: 43px;
        line-height: 39px;
      }
    }

    h1 {
      font-size: 60px;
      line-height: 60px;

      @include breakpoint(small) {
        font-size: 50px;
        line-height: 50px;
      }
    }
  }

  &.promo-home {
    .grid-container {
      top: 25%;
      transform: translate(-50%, 0%);

      h1 {
        margin-bottom: 35px;
      }
    }
  }

  .content-nav {
    position: absolute;
    bottom: 35px;
    left: 0;
    width: 100%;
    z-index: 2;

    section {
      position: relative;
      display: flex;
      justify-content: center;

      .subpage-icon-wrap {
        position: absolute;
        left: 50%;
        top: -30px;
        transform: translateX(-50%);

        @include custom_breakpoint(800) {
          .subpage-icon {
            img {
              transform: scale(0.7);
            }
          }

        }

        @include breakpoint(small) {
          max-width: 100px;
        }

      }

      ul {
        &.subnav-left {
          padding-right: 85px;

          @include custom_breakpoint(800) {
            padding-right: 40px;
          }
        }

        &.subnav-right {
          padding-left: 85px;

          @include custom_breakpoint(800) {
            padding-left: 40px;
          }
        }

        li {
          display: inline-block;
          padding: 0 13px;

          a {
            color: $rhodeisland-white;
            text-transform: uppercase;
            letter-spacing: 0.10em;


            @include breakpoint(medium) {
              font-size: 14px;
            }

            @include custom_breakpoint(800) {
              font-size: 12px;
            }

            &:hover {
              color: $rhodeisland-gold;
            }
          }
        }
      }
    }

  }

  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  &.promo-blog {
    min-height: 450px;
    max-height: 450px;
    height: 450px;
    overflow: hidden;
    margin-top: 70px;
    background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/blog/promo-blog.png) no-repeat top center;
  }

  &.promo-half {
    min-height: 450px;
    max-height: 450px;
    height: 450px;
  }
}
