.entry {
  background-size: cover;
  position: relative;
  margin-top: -35px;
  z-index: 1;

  &.bg-white {
    .list-pages-shortcode {
      color: #555;
      text-align: left;
      font-family: open-sans, sans-serif;
      font-weight: 300;
      font-size: 21px;
      padding: 70px 0 100px;

      li {
        line-height: 38px;

        &::before {
          content: "·";
          font-size: 20px;
          vertical-align: middle;
          line-height: 20px;
        }

        a {
          color: #555;
          font-weight: 300;
          text-decoration: underline;
          -webkit-transition: all .3s;
          transition: all .3s;
          margin-left: 20px;
        }
      }
    }
  }

  &.team-profile {
    .profile-circle {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -100%);

      img {
        display: block;
        border: 5px solid #fff;
        border-radius: 50%;
      }
    }

    .row-standard-content-row {
      margin-top: 50px;
    }
  }

  .breadcrumb {
    padding: 35px 0 20px;
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    a {
      color: #fff;
      font-weight: 400;
    }

    @include breakpoint(small) {
      padding: 35px 5% 20px;
    }
  }

  &.subpage-intro {
    &.padding-xl-bottom {
      padding-bottom: 300px;

      @include breakpoint(medium) {
        padding-bottom: 220px;
      }
    }

    &.blog-main-breadcrumb {
      .breadcrumb {
        border-bottom: 1px solid #e1e1e1;

        span {
          font-family: open-sans, sans-serif;

          a {
            color: $rhodeisland-black;
            font-family: open-sans, sans-serif;
          }

          strong {
            color: $rhodeisland-blue;
          }
        }
      }
    }

    .grid-container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      .row-standard-header-row {
        .cell {
          @include breakpoint(medium) {
            width: 100%;
          }
        }
      }

      .row-standard-content-row {
        width: 80%;
        margin: 0 auto;

        @include breakpoint(small) {
          width: 100%;
        }

        .icon-gold {
          position: relative;
          margin-bottom: 20px;

          &::before {
            content: "";
            display: block;
            width: 73px;
            height: 75px;
            background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-gold-bg.png) no-repeat center;
            background-size: cover;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
        }

        .icon-title {
          font-size: 36px;
          line-height: 60px;

          @include breakpoint(small) {
            font-size: 30px;
            line-height: 34px;
          }

          &+p {
            margin: 0 0;

            @include breakpoint(medium) {
              margin-bottom: 30px;
              font-size: 13px;
              line-height: 17px;
              width: 90%;
            }
          }
        }

        p.gold {
          font-weight: 700;
          color: $rhodeisland-gold;
          margin: 10px 0 50px;
        }
      }
    }
  }

  &.minus-entry {
    z-index: 0;
  }

  .grid-container {
    .grid-x {
      &.row-standard-content-row {
        position: relative;
        z-index: 1;

        .tourlab-table {
          max-width: 1037px;
          margin: 0 auto;
          text-align: left;

          tbody tr:nth-child(even) {
            background-color: #fff;
          }

          tbody tr:nth-child(odd) {
            background-color: #ebebeb;
          }

          td,
          th {
            border: 1px solid #010101;
            padding: 25px 30px;

            @include breakpoint(medium) {
              padding: 14px 14px;
            }

            @include breakpoint(small) {
              padding: 10px 10px;
            }

            h3 {
              font-size: 35px;
              line-height: 35px;

              @include breakpoint(medium) {
                font-size: 30px;
                line-height: 30px;
              }

              @include breakpoint(small) {
                font-size: 22px;
                line-height: 22px;
              }
            }

            &.bg-gold {
              background-color: $rhodeisland-gold;
            }

            &.bg-red {
              background-color: #ef4023;

              h3 {
                color: $rhodeisland-white;
              }
            }

            &.button-center {
              text-align: center;

              @include breakpoint(medium) {
                .btn-lines-wrapper {
                  &::before {
                    width: 169px;
                    height: 53px;
                    left: -12px;
                    top: -5px;
                  }

                  a {
                    font-size: 14px;
                    min-width: 150px;
                    height: 40px;
                    line-height: 36px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .overlay-image {
    position: absolute;

    @include breakpoint(medium) {
      width: 23%;
    }

    @include breakpoint(small) {
      width: 30%;
    }

    &.left {
      @include breakpoint(small) {
        left: -13%;
      }
    }

    &.bottom {
      bottom: 0;
    }

    &.right {
      right: 0;

      @include breakpoint(small) {
        right: -10%;
      }
    }
  }

  &.header-darkgreen {
    h3 {
      color: $rhodeisland-black;
    }
  }

  h3 {
    margin-bottom: 0;
    font-size: 50px;
    line-height: 58px;
    letter-spacing: 0.025em;

    @include breakpoint(medium) {
      font-size: 40px;
      line-height: 48px;
    }

    @include breakpoint(small) {
      font-size: 36px;
      line-height: 44px;
    }

    &.red {
      color: $rhodeisland-darkred;
    }

    &.white {
      color: $rhodeisland-white;
    }

    &.darkblue {
      color: $rhodeisland-darkblue;
    }

    &.gold {
      color: $rhodeisland-gold;
    }
  }

  h2 {
    font-size: 62px;
    line-height: 57px;

    @include breakpoint(medium) {
      font-size: 54px;
      line-height: 50px;
    }

    @include breakpoint(small) {
      font-size: 42px;
      line-height: 42px;
    }

    &.red {
      color: $rhodeisland-darkred;
    }

    &.white {
      color: $rhodeisland-white;
    }

    &.gold {
      color: $rhodeisland-gold;
    }
  }

  p {
    font-size: 21px;
    line-height: 32px;
    margin: 30px 0;
    font-weight: 300;

    @include breakpoint(medium) {
      font-size: 17px;
      line-height: 27px;
    }

    @include breakpoint(small) {
      font-size: 16px;
      line-height: 27px;

      strong {
        font-size: 16px;
      }
    }

    a {
      font-family: open-sans, sans-serif;

      &.blue {
        color: #00a0c2;
      }
    }
  }

  &.row-testimonials {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    color: #fff;

    h2 {
      &::after {
        content: "";
        display: block;
        width: 80%;
        height: auto;
        max-width: 178px;
        min-height: 30px;
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/homepage/testimonial-bar.png) no-repeat center;
        background-size: contain;
        margin: 30px auto 0;
      }

      @include breakpoint(medium) {
        font-size: 45px;
        line-height: 40px;
      }
    }

    p {
      font-size: 21px;

      @include breakpoint(medium) {
        font-size: 17px;
        line-height: 23px;
      }
    }

    .author {
      font-family: cortado, sans-serif;
      font-weight: 400;
      font-style: normal;
      text-transform: none;
      color: $rhodeisland-black;
      font-size: 26px;
      margin: 0px 0px !important;
      line-height: 30px;
      letter-spacing: 0.08em;

      @include breakpoint(small) {
        font-size: 18px;
      }
    }

    .source {
      margin: 0px 0px !important;
      font-size: 17px;
    }

    .owl-nav button {
      position: absolute;
      transform: translateY(-50%);

      &:hover {
        background: none;
      }

      span {
        display: block;
        background-image: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-slide-bg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 88px;
        height: 88px;
        text-align: center;
        line-height: 84px;

        @include breakpoint(medium) {
          width: 70px;
          height: 70px;
          line-height: 70px;
        }

        img {
          @include breakpoint(medium) {
            max-width: 16px;
          }
        }
      }

      &.owl-prev {
        left: -20%;
        top: 50%;

        @include breakpoint(medium) {
          left: -17%;
        }
      }

      &.owl-next {
        right: -20%;
        top: 50%;

        @include breakpoint(medium) {
          right: -17%;
        }

        img {
          margin-left: 5px;
        }
      }
    }
  }

  &.mailing-list {
    padding: 100px 0;

    .row-standard-header-row {
      p {
        margin: 20px 0 40px;
      }
    }

    .wpcf7 {
      .grid-x {
        .cell {
          margin: 0 10px;

          @include breakpoint(small) {
            margin: 10px 10px;
          }

          input {
            border: none;
            background-color: #fff;
            border-radius: 5px;
            padding: 8px 22px;
            margin-bottom: 0;

            &::placeholder {
              color: $rhodeisland-black;
            }
          }
        }
      }
    }

    .text-center {
      margin-top: 50px;

      .btn-lines-wrapper {
        input {
          width: 185px;
          height: 46px;
          line-height: 42px;
          border: 2px solid $rhodeisland-black;
          background: none;
          color: $rhodeisland-black;
          border-radius: 50px;
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }

  &.image-pod {
    background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/homepage/blog-bg.png) repeat-x center top;
    background-size: cover;
    height: auto;

    &.more-slider {
      .row-standard-blocks-main #eq1 .cell .pod-image-wrap .pod-content-wrapper {
        text-align: left;
        padding: 30px 30px 100px;
      }

      .row-standard-blocks-main {
        width: 80%;
        margin: 0 auto;
        max-width: 1170px;
        margin: 50px auto 40px;
      }
    }

    &.image-with-text {
      .row-standard-blocks-main #eq1 .cell .pod-image-wrap .pod-content-wrapper {
        padding: 30px 30px 150px;
      }
    }

    @include breakpoint(small) {
      padding-bottom: 50px;
    }

    &.press-review {
      background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/rough-white-bg.png) no-repeat center top;
      background-size: 100%;

      .two-column-block {
        display: flex;
        justify-content: center;
        padding: 87px 0;

        @include breakpoint(small) {
          display: block;

          .pod-content-wrapper {
            padding: 0 20px;
          }
        }

        h3 {
          font-size: 35px;
          line-height: 39px;
          letter-spacing: 0;
        }

        .image-wrapper {
          padding-right: 50px;
          min-width: 323px;

          @include breakpoint(medium) {
            min-width: 223px;
          }

          @include breakpoint(small) {
            width: 100%;
            min-width: 100%;
          }
        }

        .pod-content-wrapper {
          p {
            margin-bottom: 50px;
          }
        }
      }
    }

    &.long-gray-bg {
      background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/long-rough-gray.png) no-repeat center top;
      background-size: cover;

      @include breakpoint(medium) {
        padding-bottom: 0;
        background-size: auto;

        .row-standard-blocks-main {
          background-color: #eee;
        }
      }
    }

    .row-standard-blocks-main {
      margin-top: 60px;

      @include breakpoint(medium) {
        margin-top: 30px;
      }

      @include breakpoint(small) {
        margin-top: 0;
      }

      #eq1 {
        .cell {
          padding: 0 0;
          margin: 1px 0;
          position: relative;

          .pod-image-wrap {
            border: 5px solid #fff;
            margin: 1px;
            background-color: #fff;
            height: 100%;

            .image-wrapper {
              a {
                display: block;
                position: relative;
                max-height: 235px;

                &::after {
                  content: "";
                  display: block;
                  width: 100%;
                  height: 5px;
                  background-color: rgba(0, 160, 194, 0.75);
                  position: absolute;
                  left: 0;
                  bottom: 0;
                }

                img {
                  width: 100%;
                }
              }
            }

            .pod-content-wrapper {
              text-align: left;
              padding: 30px 30px 100px;

              @include breakpoint(medium) {
                padding: 30px 20px 40%;
                max-height: 370px;
              }

              h3 {
                font-size: 34px;
                line-height: 38px;

                @include breakpoint(medium) {
                  font-size: 30px;
                  line-height: 34px;
                }

                &.blue {
                  color: $rhodeisland-blue;
                }

                &.gold {
                  color: $rhodeisland-gold;
                }

                @include breakpoint(small) {
                  font-size: 28px;
                  line-height: 32px;
                }
              }

              h2 {
                font-size: 38px;
                line-height: 42px;

                @include breakpoint(medium) {
                  font-size: 32px;
                  line-height: 35px;
                }
              }

              p {
                line-height: 26px;
                margin-bottom: 0;
                font-family: open-sans, sans-serif;
                font-size: 18px;
                font-weight: 300;
                margin-top: 15px;

                @include breakpoint(medium) {
                  line-height: 24px;
                  font-size: 16px;
                  margin-top: 20px;
                }
              }

              .btn-lines-wrapper {
                position: absolute;
                left: 30px;
                bottom: 30px;

                &::before {
                  width: 180px;
                  height: 52px;
                  top: -6px;
                }

                a {
                  font-size: 15px;
                  min-width: 150px;
                  height: 39px;
                  line-height: 35px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.row-slider-wrap {
    background-position: center bottom;
    padding-bottom: 50px;
    margin-top: 0;

    .row-slider-mobile,
    .row-slider,
    .row-blocks {
      .row-slider-slide {
        section {
          position: relative;

          .slide-desc {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0 80px;

            section {
              max-width: 354px;

              strong {
                font-weight: 100;
                font-size: 40px;
              }

              h3 {
                font-size: 28px;
                line-height: 35px;
              }

              p {
                margin: 0 0;
              }
            }
          }
        }

        &.row-slider-slide-mobile {
          section {
            .slide-desc {
              padding: 0 30px;

              strong {
                font-weight: 100;
                font-size: 30px;
              }

              h3 {
                font-size: 18px;
                line-height: 24px;
              }

              p {
                margin: 0 0;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }

      .owl-nav {
        button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 60px;
          height: 60px;
          margin: 0 0;

          &.owl-prev {
            left: calc(25% - 30px);

            @include breakpoint(medium) {
              left: 13%;
            }
          }

          &.owl-next {
            right: calc(25% - 30px);

            @include breakpoint(medium) {
              right: 13%;
            }
          }

          &:hover {
            background: none;
          }
        }
      }
    }
  }

  &.row-tab-wrap {
    z-index: 0;
    padding-bottom: 120px;

    #collapsing-tabs {
      position: relative;
      z-index: 0;
      --i: -1;
      display: inline-block;
      border-radius: 50px;
      background-color: #f1f2f2;
      margin: 80px 0 70px;

      @include breakpoint(medium) {
        margin: 40px 0 20px;
      }

      @include breakpoint(small) {
        width: 100%;
        margin: 20px 0 20px;
      }

      &[style="--i:1"] {
        &::before {
          @include breakpoint(small) {
            left: 45%;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        width: calc((100% / 2) + 20px);
        top: -10px;
        left: calc(var(--i) * (100% / 2) - 10px);
        height: calc(100% + 20px);
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/tab-active.png) no-repeat center center;
        background-size: 100% 100%;
        transition: all .3s ease-in-out;

        @include breakpoint(medium) {
          display: none;
        }
      }

      li {
        min-width: 230px;
        border: 1px solid $rhodeisland-black;

        @include breakpoint(small) {
          min-width: 50%;
        }

        &:first-child {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }

        &:last-child {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }

        &:not(:last-child) {
          border-right: none;
        }

        a {
          padding: 15px;
          font-size: 19px;
          font-family: proxima-nova, sans-serif;
          font-weight: 700;
          color: $rhodeisland-black;

          &:hover {
            background: transparent;
          }

          @include breakpoint(small) {
            padding: 15px 17px;
          }
        }

        &.tabs-title {
          a {
            &:focus {
              background: none;
            }

            &[aria-selected=true] {
              background: none;
            }
          }
        }

        &.is-active {
          @include breakpoint(medium) {
            background-color: #02a0c2;
          }

          a {
            color: #fff !important;
          }
        }

        @include breakpoint(small) {
          width: 50%;

          a {
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
          }
        }
      }
    }

    .tabs-content {
      border: none;

      h3 {
        font-size: 38px;

        @include breakpoint(small) {
          font-size: 25px;
          line-height: 28px;
        }
      }

      p {
        font-family: open-sans, sans-serif;
        font-weight: 300;
      }
    }

    &.tab-align-left {
      .tabs-content {
        .tabs-panel {
          ul {
            text-align: left;
            width: 82%;
            margin: 0 auto;

            li {
              list-style-type: disc;
              margin: 14px auto;
              font-size: 20px;
              line-height: 27px;
            }
          }
        }
      }
    }

    &.tab2 {
      z-index: 1;
      background-size: contain;
      padding-bottom: 120px;

      #collapsing-tabs {
        margin: 0 0 50px;

        @include breakpoint(small) {
          margin-bottom: 0;
        }

        &::before {
          content: "";
          position: absolute;
          z-index: -1;
          width: calc((100% / 2) + 20px);
          top: -10px;
          left: calc(var(--i) * (100% / 2) - 10px);
          height: calc(100% + 20px);
          background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/tab-active.png)no-repeat center center;
          background-size: 100% 100%;
          transition: .3s ease-in-out;
        }
      }
    }
  }

  &.image-pods-wrapper {
    padding: 80px 0;

    &.has-bg {
      @include custom_breakpoint(780) {
        padding: 80px 5%;
        background-size: 100%;
        padding-bottom: 300px !important;
      }

      @include breakpoint(small) {
        padding: 80px 25px 50px !important;
        background-size: 100%;
      }
    }

    &.miss-tours {
      @include breakpoint(medium) {
        padding: 80px 5%;
      }

      @include breakpoint(small) {
        padding: 80px 25px 120px !important;
        background-size: cover;
      }
    }

    .image-pods-header {
      padding-bottom: 40px;
    }

    .image-pods-footer {
      margin-top: 30px;
    }

    .image-pods {
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100%;
      margin-top: -15px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      @include breakpoint(medium) {
        width: 100%;
        margin: 0 auto;
      }

      @include breakpoint(small) {
        display: block;
        width: 90%;
        margin: -15px auto 0;
      }

      section {
        z-index: 2;

        .pod {
          margin: 8px 4px;
          max-width: 506px;
          min-height: 502px;
          border: 5px solid #fff;
          position: relative;

          @include breakpoint(medium) {
            min-height: 400px;
          }

          @include breakpoint(small) {
            min-height: 350px;
            overflow: auto;
          }

          .pod-image {
            margin: 0 0;
          }

          section {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;

            .pod-desc {
              background-color: rgba(0, 0, 0, 0.9);
              padding: 35px 20px;
              color: #fff;
              width: 100%;
              position: relative;
              text-align: left;

              @include breakpoint(small) {
                padding: 25px 20px;
              }

              .pod-icon {
                position: absolute;
                right: 36px;
                top: 0;
                transform: translateY(-50%);
                margin: 0 0;
                min-height: 99px;
                display: flex;
                justify-content: center;
                align-items: center;

                @include breakpoint(small) {
                  right: 16px;
                  min-height: 50px;

                  img {
                    height: 50px;
                  }
                }
              }

              h4 {
                color: $rhodeisland-gold;
                margin: 0 0 10px;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: 0.02em;
                width: 80%;

                @include breakpoint(medium) {
                  margin: 0 0 5px;
                }

                @include breakpoint(small) {
                  font-size: 16px;
                  line-height: 16px;
                  margin: 0 0 5px;
                }
              }

              h3 {
                margin: 0;
                font-size: 38px;
                line-height: 40px;
                width: 80%;

                @include breakpoint(medium) {
                  font-size: 32px;
                  line-height: 32px;
                  width: 100%;
                }

                @include custom_breakpoint(770) {
                  font-size: 25px;
                  line-height: 30px;
                }

                @include breakpoint(small) {
                  font-size: 26px;
                  line-height: 28px;
                  width: 100%;
                }
              }

              h5 {
                color: $rhodeisland-blue;
                margin: 0;
                font-size: 13px;
                line-height: 27px;
                letter-spacing: 0.06em;
                width: 80%;
              }

              .hidden-content {
                width: 80%;
                display: none;

                @include custom_breakpoint(770) {
                  height: 160px;
                }

                @include breakpoint(medium) {
                  width: 100%;
                }

                @include breakpoint(small) {
                  height: 142px;
                }

                p {
                  font-size: 18px;
                  font-weight: 300;
                  line-height: 27px;

                  @include breakpoint(medium) {
                    font-size: 16px;
                    line-height: 25px;
                  }

                  @include custom_breakpoint(770) {
                    font-size: 15px;
                    line-height: 24px;
                    margin: 10px 0 20px;
                  }

                  @include breakpoint(small) {
                    font-size: 14px;
                    line-height: 22px;
                    margin: 10px 0 20px;
                  }
                }

                a {
                  color: $rhodeisland-white;
                  font-weight: 700;
                  display: inline-block;
                  width: 155px;
                  height: 39px;
                  line-height: 35px;
                  border: 2px solid #fff;
                  border-radius: 50px;
                  text-align: center;
                  font-size: 15px;
                  transition: all 0.3s;

                  &:hover {
                    background-color: #fff;
                    color: rgba(0, 0, 0, 0.9);
                    transition: all 0.3s;
                  }

                  @include breakpoint(small) {
                    font-size: 13px;
                    height: 35px;
                    line-height: 31px;
                    width: 130px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .background-image-2 {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;

      img {
        width: 100%;

        @include breakpoint(medium) {
          max-width: 170%;
          width: 170%;
        }

        @include breakpoint(small) {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    &.translateleft {
      .image-pods {
        >section {
          &:first-child {
            transform: translateY(-120px);
          }

          &:nth-child(2) {
            transform: translateY(-94px);

            @include breakpoint(small) {
              transform: translateY(-120px);
            }
          }
        }
      }
    }

    &.translateright {
      .image-pods {
        section:last-child {
          transform: translateY(-120px);
        }
      }
    }

    &.multi-pods {
      .image-pods {
        @include breakpoint(small) {
          transform: translateY(-150px);
        }

        >section {
          &:first-child {
            transform: translateY(-150px);

            @include breakpoint(small) {
              transform: translateY(0);
            }
          }

          &:nth-child(2) {
            transform: translateY(-40px);

            @include breakpoint(small) {
              transform: translateY(0);
            }
          }
        }
      }
    }

    &.multi-pods-team {
      .image-pods {
        align-items: flex-start;

        >section {
          &:first-child {
            transform: translateY(-150px);
          }

          &:nth-child(2) {
            transform: translateY(-70px);
          }

          .pod section .pod-desc {
            .hidden-content {
              @include breakpoint(medium) {
                height: 40px;
              }

              @include breakpoint(small) {
                height: 50px;
              }
            }
          }
        }
      }
    }

    .masonry-grid {
      width: 70%;
      margin: 0 auto;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      position: relative;

      @include breakpoint(medium) {
        width: 100%;
        margin-bottom: -50px;
      }

      @include breakpoint(small) {
        margin-bottom: 0;
        display: block;
      }

      section {
        width: calc(33% - 5px);

        @include breakpoint(medium) {
          width: calc(33% - 1px);
        }

        @include breakpoint(small) {
          width: 100%;
        }

        &:first-child {
          transform: translateY(-230px);

          @include breakpoint(medium) {
            transform: translateY(-170px);
          }
        }

        &:nth-child(2) {
          transform: translateY(-130px);

          @include breakpoint(medium) {
            transform: translateY(-110px);
          }

          @include breakpoint(small) {
            transform: translateY(-170px);
          }
        }

        &:nth-child(3) {
          @include breakpoint(small) {
            transform: translateY(-170px);
          }
        }
      }

      .grid-item {
        width: 100%;
        border: 5px solid #fff;
        margin-bottom: 15px;

        @include breakpoint(medium) {
          margin-bottom: 6px;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  &.row-map {
    .row-standard-header-row {
      max-width: 880px;
      margin: 0 auto;
    }

    .map-direction-wrapper {
      .map-direction {
        height: 600px;
        max-width: 880px;
        width: 90%;

        text-align: center;
        margin: 0 auto;
        border: 5px solid #f6f6f6;

        @include breakpoint(small) {
          height: 400px;
        }

        #map {
          width: auto;
          height: 100%;
        }
      }

      .map-form {
        max-width: 700px;
        width: 90%;
        margin: 53px auto;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50px;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, .1);
        position: relative;

        @include breakpoint(small) {
          height: 45px;
        }

        input {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          border: none;
          border: 1px solid #414042;
          border-right: none;
          box-shadow: none;
          background-color: transparent;
          margin-bottom: 0;
          width: calc(100% - 200px);
          height: 100%;
          padding: 0 0;
          background: linear-gradient(0deg, rgba(241, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 41%);
          text-indent: 40px;

          @include breakpoint(small) {
            text-indent: 14px;
            width: calc(100% - 100px);
          }
        }

        button {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          width: 200px;
          text-align: center;
          outline: none;
          color: $rhodeisland-white;
          font-weight: 700;
          height: 100%;
          border: 1px solid #414042;
          z-index: 2;
          position: relative;
          cursor: pointer;

          @include breakpoint(small) {
            width: 120px;
            font-size: 14px;
            background-color: #009cbc;
          }
        }

        &:before {
          content: "";
          display: block;
          background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/btn-bg.png) no-repeat center;
          background-size: contain;
          width: 230px;
          height: 76px;
          position: absolute;
          right: -10px;
          top: -10px;
          z-index: 1;

          @include breakpoint(small) {
            background: none;
          }
        }
      }
    }
  }

  &.bg-pos-bottom {
    background-position: center bottom;
  }

  &.rough-white-bg {
    background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/rough-white-bg.png) no-repeat center top;
    background-size: 100%;
  }

  &.contact-form {
    background-size: 100%;

    @include breakpoint(small) {
      padding-bottom: 50px;
    }

    ._form ._full_width {
      @include breakpoint(small) {
        width: 100%;

        &._button-wrapper {
          margin-top: 20px;
        }
      }
    }

    .cell {
      margin-bottom: 20px;

      label {
        span {

          input,
          select,
          textarea {
            border: 1px solid #a1a1a1;
            background-color: #f1f2f2;
            border-radius: 10px;
            padding: 5px 10px;

            @include breakpoint(small) {
              padding: 0 10px;
            }
          }
        }

        &.select {
          select {
            background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-archieve.png)no-repeat right center;
            background-color: #f1f2f2;
            background-size: contain;
            border: 1px solid #a1a1a1;
            background-color: #f1f2f2;
            border-radius: 10px;
            padding: 5px 10px;

            @include breakpoint(small) {
              padding: 0 10px;
            }
          }
        }
      }

      .checkbox {
        font-size: 15px;
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        .checkbox-769 {
          .wpcf7-checkbox {
            border: 1px solid #c0c0c1;
            min-width: 20px;
            min-height: 20px;
            display: inline-block;
            background-color: #f3f3f3;
            border-radius: 2px;
            margin-right: 14px;
          }

          .wpcf7-not-valid-tip {
            position: absolute;
            min-width: 300px;
          }
        }
      }

      input,
      textarea,
      .wpcf7-select {
        border: none;
        background: transparent;
        box-shadow: none;
        padding: 0;
        margin: 0;
        line-height: 30px;
        font-size: 19px;
        color: #414042;

        &::placeholder {
          color: #414042;
        }

        @include breakpoint(small) {
          line-height: 16px;
          font-size: 12px;
        }
      }

      textarea {
        @include breakpoint(small) {
          padding-top: 10px;
        }
      }

      .btn-lines-wrapper {
        .wpcf7-submit {
          text-align: center;
          border: 2px solid $rhodeisland-black;
        }
      }
    }
  }

  &.white-bg {
    background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/rough-white-bg.png) no-repeat top center;
    background-size: 100%;
  }

  &.grey-bg {
    background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/rought-gray-bg.png) no-repeat top center;
    background-size: 100%;

    &.icon-blocks-gray {
      padding-bottom: 100px;
      background-size: cover;
    }
  }

  &.general-inquiries {
    position: relative;
    padding-bottom: 500px;

    .row-standard-header-row {
      width: 60%;
      margin: 0 auto;

      @include breakpoint(medium) {
        width: 90%;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 500px;

      background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/contact/bg1.png) no-repeat top center;
      background-size: cover;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .row-standard-header-row {
      padding-bottom: 50px;
    }

    .row-standard-content-row {
      .cell {
        margin-bottom: 20px;

        label {
          border: 1px solid #a1a1a1;
          background-color: #f1f2f2;
          border-radius: 10px;
          padding: 5px 10px;

          @include breakpoint(small) {
            padding: 0 10px;
          }

          &.select {
            background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-archieve.png)no-repeat right center;
            background-color: #f1f2f2;
            background-size: contain;
          }
        }

        .btn-lines-wrapper {
          .wpcf7-submit {
            text-align: center;
            border: 2px solid $rhodeisland-black;
          }
        }

        .checkbox {
          font-size: 15px;
          display: flex;
          align-items: center;
          padding-bottom: 30px;

          .checkbox-769 {
            border: 1px solid #c0c0c1;
            min-width: 20px;
            min-height: 20px;
            display: inline-block;
            background-color: #f3f3f3;
            border-radius: 2px;
            margin-right: 14px;
          }
        }

        input,
        textarea,
        select {
          border: none;
          background: transparent;
          box-shadow: none;
          padding: 0;
          margin: 0;
          line-height: 30px;
          font-size: 19px;
          color: #414042;

          @include breakpoint(small) {
            font-size: 14px;
          }

          &::placeholder {
            color: #414042;
          }
        }
      }
    }
  }

  &.row-faq-wrap {
    .tourlab-accordion {
      padding-top: 100px;

      .accordion-item {
        border-bottom: 1px solid #a1a1a1;
        padding: 30px 0;

        .accordion-title {
          border: none;
          background-color: transparent;
          padding: 0;

          p {
            font-weight: 800;
            color: #00a0c2;

            width: 80%;
            margin: 0 auto;

            @include breakpoint(small) {
              font-size: 16px;
              line-height: 18px;
              width: 70%;
            }
          }

          &::before {
            content: "";
            width: 25px;
            height: 18px;
            background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-faq-arrow.png) no-repeat center;
            background-size: contain;
            display: block;
            transform: rotate(0);
            transition: transform 0.3s;

            @include breakpoint(small) {
              width: 14px;
              height: 10px;
            }
          }
        }

        .accordion-content {
          border: none;
          background: none;

          p {
            margin: 0 0;
          }
        }

        &.is-active {
          .accordion-title {
            &::before {
              transform: rotate(180deg);
              transition: transform 0.3s;
            }
          }
        }
      }
    }

    .row-faq-footer {
      margin: 50px 0;
    }
  }

  &.blog-image-pods {
    .row-standard-image-pods {
      padding: 40px 0;

      .pod {
        min-height: 512px;

        @include breakpoint(small) {
          min-height: 420px;
        }

        section {
          .pod-desc {
            h3 {
              font-size: 34px;
              letter-spacing: 0;
              line-height: 38px;
              width: 100%;

              @include breakpoint(medium) {
                font-size: 32px;
                line-height: 36px;
              }

              @include breakpoint(small) {
                font-size: 30px;
                line-height: 34px;
              }
            }

            .hidden-content {
              p {
                font-size: 15px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }

  .job-information-content {
    .cell {
      padding: 54px 0;

      .btn-wraps {
        margin-top: 50px;

        .btn-lines-wrapper {
          margin-right: 20px;

          &::before {
            width: 170px;
            height: 52px;
            left: -10px;
            top: -6px;

            @include breakpoint(small) {
              width: 154px;
              height: 47px;
            }
          }

          .btn-lines {
            font-size: 15px;
            min-width: 154px;
            height: 39px;
            line-height: 35px;

            @include breakpoint(small) {
              min-width: 140px;
              height: 35px;
              line-height: 31px;
            }
          }
        }
      }

      h3 {
        font-size: 36px;
        letter-spacing: 0;
        line-height: 43px;
      }

      .blue {
        font-size: 14px;
        font-family: proxima-nova, sans-serif;
        text-transform: uppercase;
        color: #009cbc;
        margin: 0 0;
      }
    }
  }

  &.job-application {
    .row-standard-header-row {
      width: 60%;
      margin: 0 auto 50px;
    }

    .row-standard-content-row {
      .wpcf7-form {
        label {
          margin-bottom: 20px;

          input {
            border: 1px solid #a1a1a1;
            border-radius: 10px;
            background-color: #fff;
            margin: 0 0;

            border: none;
            outline: none;

            height: 50px;
            line-height: 50px;
            padding: 0 14px;

            font-size: 19px;
            font-family: open-sans, sans-serif;
            color: #414042;
            font-weight: 300;

            &::placeholder {
              color: #414042;
            }
          }

          &.file {
            border: 1px solid #a1a1a1;
            border-radius: 10px;
            background-color: #fff;

            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 14px;
            margin-bottom: 20px;
            font-weight: 300;
            font-size: 19px;
            font-family: open-sans, sans-serif;
            color: #414042;

            button {
              font-size: 16px;
              font-weight: 500;
              border: 1px solid #cccccc;
              padding: 10px 40px;
              color: #fff;
              background: rgb(1, 136, 165);
              background: linear-gradient(0deg,
                  rgba(1, 136, 165, 1) 0%,
                  rgba(40, 193, 219, 1) 57%,
                  rgba(255, 255, 255, 1) 100%);
              cursor: pointer;
            }

            .wpcf7-form-control-wrap {
              display: none;
            }
          }
        }

        .select {
          padding: 0 0;
          margin: 0 0 20px;
          width: 100%;
          display: block;

          .wpcf7-not-valid-tip {
            font-size: 13px;
          }

          select {
            background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-archieve.png) no-repeat 100%;
            background-color: #fff;
            background-size: contain;
            border-radius: 10px;
            border: 1px solid #a1a1a1;
            height: 50px;
            line-height: 50px;
            border: none;
            padding: 0 14px;
            -webkit-box-shadow: none;
            box-shadow: none;
            line-height: 30px;
            font-family: open-sans, sans-serif;
            color: #414042;
            font-weight: 300;
            font-size: 19px;
            margin: 0 0;
          }
        }
      }
    }
  }
}

.entry {
  &.bkg-image-row {
    height: 700px;

    @include breakpoint(medium) {
      height: 300px;
    }

    @include breakpoint(small) {
      height: 200px;
    }
  }

  &.job_list {
    @include breakpoint(medium) {
      margin-bottom: -100px;
    }
  }
}

.icon-blocks {
  &.has-bg {
    @include custom_breakpoint(780) {
      padding-bottom: 300px !important;
    }

    @include breakpoint(small) {
      padding-bottom: 200px !important;
    }
  }

  .row-standard-header-row {
    padding-bottom: 80px;
  }

  .row-standard-footer-row {
    padding-top: 80px;
    position: relative;
    z-index: 1;
  }

  .icon-contents {
    position: relative;
    z-index: 1;

    @include breakpoint(medium) {
      width: 95%;
      margin: 0 auto;
    }

    p {
      margin: 0 0;
    }

    .icon-content {
      section {
        padding: 0 30px;

        @include breakpoint(medium) {
          padding: 0 10px 30px;
        }

        @include breakpoint(small) {
          padding: 0 5px 30px;
        }

        h3 {
          font-size: 37px;
          line-height: 42px;
          margin: 10px 0;

          @include breakpoint(medium) {
            font-size: 32px;
            line-height: 37px;
          }

          @include breakpoint(small) {
            font-size: 25px;
            line-height: 27px;
          }
        }

        p {
          margin: 0 0;
          font-size: 19px;
          line-height: 28px;

          @include breakpoint(medium) {
            font-size: 17px;
            line-height: 26px;
          }

          @include breakpoint(small) {
            font-size: 14px;
            line-height: 23px;
          }

          &.icon-img {
            min-height: 116px;

            span {
              display: block;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              min-width: 100%;
            }
          }

          &.magnifier {
            span {
              left: 52%;
            }
          }

          &.dish {
            span {
              top: 55%;
            }
          }
        }

        div {
          padding-top: 14px;

          a {
            font-family: open-sans, sans-serif;
            font-weight: 300;
            color: $rhodeisland-black;
          }
        }
      }
    }
  }

  &.icon-blocks-gray {
    .icon-img {
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/homepage/icon-gray-bg.png) no-repeat center;
        background-size: 100%;
        width: 130px;
        height: 135px;
      }
    }
  }

  .background-image {
    position: absolute;
    width: 100%;

    &.bottom {
      bottom: 0;
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      width: 100%;

      @include breakpoint(medium) {
        width: 170%;
        max-width: 170%;
      }
    }
  }
}

.region-map {
  color: #fff;
  padding-top: 100px;
  padding: 100px 5% 0;

  @include custom_breakpoint(1230) {
    padding-bottom: 100px;
  }

  @include breakpoint(medium) {
    padding: 100px 5% 150px;

    .grid-container {
      .grid-x {
        align-items: center;
      }
    }
  }

  @include breakpoint(small) {
    padding-bottom: 80px;
  }

  .region-map-intro {
    padding-right: 5%;

    @include breakpoint(medium) {
      padding-right: 0;
    }
  }

  ul {
    margin: 10px 0 40px;

    li {
      display: inline-block;

      margin: 8px 0;
      margin-right: 17px;

      a {
        color: $rhodeisland-white;
        font-weight: 600;
        position: relative;
        display: block;

        &::before {
          content: "";
          display: block;
          height: 2px;
          width: 100%;
          background-color: $rhodeisland-white;
          position: absolute;
          left: 0;
          bottom: 0;
        }

        &::after {
          content: "";
          display: block;
          height: 2px;
          width: 100%;
          background-color: $rhodeisland-gold;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0%;
          overflow: hidden;
          transition: width 0.3s;
        }

        &:hover {
          color: $rhodeisland-gold;

          &::after {
            width: 100%;
            transition: width 0.3s;
          }
        }
      }

      &.active {
        a {
          color: $rhodeisland-gold;

          &::after {
            width: 100%;
            transition: width 0.3s;
          }
        }
      }
    }
  }

  .map-wrapper {
    padding-left: 10%;

    @include breakpoint(medium) {
      padding-left: 3%;
    }

    @include breakpoint(small) {
      padding: 100px 0;
    }

    .map-wrap {
      .map {
        position: relative;
        transform: translateY(-150px);

        @include custom_breakpoint(1000) {
          transform: translateY(0);
        }

        p {
          margin: 0 0;
        }

        .hover-map {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }

        .marker {
          display: block;
          position: absolute;
          z-index: 3;

          &.marker-0 {
            top: 50px;
            left: 231px;

            @include breakpoint(small) {
              top: 16px;
              left: 146px;
            }
          }

          &.marker-1 {
            top: 121px;
            left: 278px;

            @include breakpoint(small) {
              top: 63px;
              left: 170px;
            }
          }

          &.marker-2 {
            top: 214px;
            left: 265px;

            @include breakpoint(small) {
              top: 124px;
              left: 162px;
            }
          }

          &.marker-3 {
            top: 367px;
            right: 86px;

            @include breakpoint(small) {
              top: 225px;
              right: 49px;
            }
          }

          &.marker-4 {
            top: 420px;
            left: 144px;

            @include breakpoint(small) {
              top: 249px;
              left: 78px;
            }
          }

          &.marker-5 {
            bottom: 7px;
            left: 158px;

            @include breakpoint(small) {
              bottom: 5px;
              left: 94px;
            }
          }

          &.marker-6 {
            bottom: 165px;
            right: 106px;

            @include breakpoint(small) {
              bottom: 103px;
              right: 63px;
            }
          }
        }

        .area {
          position: absolute;
          width: 100%;
          height: 50px;

          &.area-1 {
            left: 5%;
            top: 0;
            width: 62%;
            height: 250px;
          }

          &.area-2 {
            left: 5%;
            top: 250px;
            width: 62%;
            height: 126px;

            &::after {
              content: "";
              display: block;
              right: -125px;
              top: 57px;
              width: 188px;
              height: 212px;

              position: absolute;
            }
          }

          &.area-3 {
            left: 0;
            bottom: 33px;
            width: 54%;
            height: 273px;
          }

          &.area-4 {
            left: 158px;
            bottom: 0;
            width: 50px;
            height: 27px;
          }
        }
      }
    }
  }
}

.dynamic-content {
  background-image: url(/wp-content/uploads/2020/03/RoughEdge.png);
  background-position: center top;
  padding: 100px 0 135px;

  &.job_list {
    @include breakpoint(small) {
      padding-top: 30px;
      padding-bottom: 60px;
    }
  }

  &.blog_main {
    padding: 50px 0 135px;

    .grid-container:first-child {
      margin-bottom: 43px;
    }
  }

  &.blog {
    background-image: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/homepage/blog-bg.png);

    padding: 50px 0 50px;
  }

  .instagram-block {
    .row-dynamic-header {
      h2 {
        margin-bottom: 60px;
      }
    }

    .row-dynamic-content {
      .cell {
        margin: 5px 5px;

        @include breakpoint(small) {
          margin: 0 0;

          &:last-child {
            display: none;
          }
        }

        p {
          margin: 0 0;
          position: relative;
          max-height: 195px;

          @include breakpoint(small) {
            margin: 5px 5px;
          }

          a {
            display: block;

            img {
              width: 100%;
            }
          }

          &::after {
            content: "";
            display: block;
            width: 29px;
            height: 29px;
            background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-insta.png) no-repeat center;
            background-size: cover;
            position: absolute;
            bottom: 10px;
            left: 10px;
          }
        }
      }
    }

    .row-dynamic-footer {
      padding-top: 36px;

      .btn-lines-wrapper {
        &::before {
          z-index: 0;
        }

        a {
          display: block;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

.dynamic-content {
  .blog-slider {
    .owl-dots {
      display: none;

      @include breakpoint(medium) {
        display: block;
      }
    }
  }
}

.blog-slider,
.blog-blocks {
  padding: 30px 0 85px;
  margin: 0 auto;

  @include custom_breakpoint(1350) {
    width: 90%;
  }

  @include custom_breakpoint(780) {
    width: 95%;
  }

  @include breakpoint(small) {
    width: 90%;
  }

  .owl-stage {
    display: flex;
    flex-wrap: wrap;

    .owl-item {
      display: flex;
      height: auto !important;
    }
  }

  .blog-slide {
    .blog-inner {
      background: #fff;
      border: 5px solid #fff;
      min-height: 440px;
      height: 100%;

      @include breakpoint(medium) {
        min-height: 420px;
      }

      @include breakpoint(small) {
        min-height: auto;
      }

      a {
        display: block;

        p {
          margin: 0 0;
          padding: 20px 23px;

          span,
          strong {
            display: block;
            font-weight: 700;
          }

          span {
            color: $rhodeisland-blue;
            font-size: 14px;
            line-height: 28px;
          }

          strong {
            font-size: 17px;
            line-height: 24px;
            color: $rhodeisland-black;
            width: 80%;

            @include breakpoint(medium) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .owl-nav {
    button {
      position: absolute;
      top: 35%;
      transform: translateY(-50%);

      span {
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/blog-slide-bg.png) no-repeat center;
        background-size: cover;
        display: block;
        padding: 10px;
        max-width: 77px;

        @include breakpoint(small) {
          max-width: 55px;
        }
      }
    }

    .owl-prev {
      left: -3%;

      @include breakpoint(small) {
        left: -10%;
      }
    }

    .owl-next {
      right: -3%;

      @include breakpoint(small) {
        right: -10%;
      }
    }
  }
}

.toggle-wrap {
  position: relative;
  z-index: 2;

  .toggle-btn-wrap {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);

    .toggle-btn {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      transform: rotate(180deg);
      transition: all 0.3s;
      outline: none;

      img {
        width: 80%;
      }

      &::before {
        content: "";
        display: block;
        width: 65px;
        height: 65px;
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/blog-slide-bg.png) no-repeat center;
        background-size: cover;
        position: absolute;
      }

      &.open {
        transform: rotate(0);
        transition: all 0.3s;
      }
    }
  }
}

.parent-pageid-1444 {
  .entry .overlay-image.bottom {
    @include breakpoint(medium) {
      bottom: 30px;
    }
  }
}
