body {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: rift, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-variant: normal;

  &.cursive {
    font-family: cortado, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
  }
}
p{
  font-family: open-sans, sans-serif;
  font-weight:300;
}
a {
  font-family: proxima-nova, sans-serif;
  font-weight:700;
  transition: all 0.2s ease-in;
}
a:hover {
  color: #666;
}
a:focus {
  color: #666;
  outline: none;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

