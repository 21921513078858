body {
  &.admin-bar {
    section {
      &.fixed-header-nav {
        top: 32px;
      }
    }
  }
}

* {
  font-variant-ligatures: none;
}

ul {
  margin: 0 0;
}

li {
  list-style: none;
}

.owl-theme {
  .owl-dots {
    .owl-dot {
      span {
        background: transparent;
        border: 1px solid $rhodeisland-blue;

      }

      &.active {
        span {
          background: $rhodeisland-blue;
        }
      }

    }
  }

  .owl-nav [class*="owl-"]:hover {
    background: none;
  }
}

.page-content {
  position: relative;
}

.nowrap {
  white-space: nowrap;
}

@each $color,
$colorVal in $colors {
  .bg-#{$color} {
    background-color: $colorVal;
  }

  .color-#{$color} {
    color: $colorVal;
  }
}

.padding-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-small-top-medium-bottom {
  padding-top: 40px;
  padding-bottom: 80px;
}

.padding-normal,
.padding-medium {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-medium-bottom {
  padding-top: 0;
  padding-bottom: 80px;
}

.padding-normal-no-bottom,
.padding-medium-no-bottom {
  padding-top: 80px;
  padding-bottom: 0;

}

.padding-medium-top-small-bottom {
  padding-top: 80px;
  padding-bottom: 40px;
}

.padding-medium-top-xl-bottom {
  padding-top: 80px;
  padding-bottom: 200px;
}

.padding-large {
  padding-top: 120px;
  padding-bottom: 120px;
}

.padding-large-bottom {
  padding-bottom: 120px;
}

.padding-large-top-xl-bottom {
  padding-top: 120px;
  padding-bottom: 250px;

  @include breakpoint(medium) {
    padding-bottom: 150px;
  }
}

.padding-xl-bottom {
  padding-bottom: 263px;
}

.padding-medium-top-large-bottom {
  padding-top: 80px;
  padding-bottom: 120px;
}

.move-down-70 {
  position: relative;
  z-index: 10;
  margin-bottom: -70px;

  &.padding-medium-no-bottom {
    @include breakpoint(small) {
      padding-bottom: 80px;
    }
  }
}

.btn-lines-wrapper {
  display: inline-block;
  position: relative;
  z-index: 2;

  .btn-lines,
  a {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    min-width: 184px;
    height: 46px;
    line-height: 42px;
    text-align: center;
    border: 2px solid $rhodeisland-white;
    display: block;
    color: $rhodeisland-white;
    font-size: 18px;
    font-weight: 700;
    transition: all 0.3s;



    &:hover {
      background-color: #fff;
      transition: all 0.3s;
      color: #000;
    }

    @include breakpoint(small) {
      font-size: 16px;
    }
  }

  &.blue-paint {
    position: relative;

    a {
      background: none;
      transition: all 1s;

      &:hover {
        background: none;
        color: #fff;
      }
    }


    &::before {
      content: "";
      display: block;
      width: 225px;
      height: 66px;
      background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/btn-blue-bg.png) no-repeat center;
      background-size: cover;
      position: absolute;
      left: -20px;
      top: -11px;
      z-index: -1;
      transition: all 0.5s;
    }

    &:hover {
      a {
        border: 2px solid #000;
        color: #000;
      }

      &::before {
        width: 225px;
        height: 66px;
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/btn-gold-bg.png) no-repeat center;
      }
    }
  }

  &.blue-paint-light {
    position: relative;

    a {
      background: none;
      transition: all 1s;

      &:hover {
        background: none;
        color: #fff;
      }
    }


    &::before {
      content: "";
      display: block;
      width: 225px;
      height: 66px;
      background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/btn-blue-bg.png) no-repeat center;
      background-size: cover;
      position: absolute;
      left: -20px;
      top: -11px;
      z-index: -1;
      transition: all 0.5s;
    }

    &:hover {
      a {
        border: 2px solid #000;
        color: #000;
      }

      &::before {
        width: 225px;
        height: 66px;
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/btn-lighter-blue.png) no-repeat center;
      }
    }
  }

  &.gold-paint {
    position: relative;


    a {
      display: block;
      color: $rhodeisland-black;
      border: 2px solid $rhodeisland-black;
      border-radius: 25px;
      background: none;

      &:hover {
        background: none;
      }

    }

    &::before {
      content: "";
      display: block;
      width: 225px;
      height: 66px;
      background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/btn-gold-bg.png) no-repeat center;
      background-size: cover;
      position: absolute;
      left: -20px;
      top: -11px;
      z-index: -1;
    }
  }

  &.brown-paint {
    position: relative;
    background: none;

    &::before {
      content: "";
      display: block;
      width: 225px;
      height: 66px;
      background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/btn-brown-bg.png) no-repeat center;
      background-size: cover;
      position: absolute;
      left: -20px;
      top: -11px;
      z-index: -1;
    }

    .btn-lines {
      background-color: transparent;

      &:hover {
        background: none;
        color: #fff;
      }
    }
  }


}

.vert-align {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.link-gallery {
  position: relative;
  color: $rhodeisland-black;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 10px;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    background: #000;
    top: 50%;
    left: -70px;
    width: 60px;
    opacity: .3;
  }

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    background: #000;
    top: 50%;
    right: -70px;
    width: 60px;
    opacity: .3;
  }

  img {
    margin-top: -4px;
    margin-right: 2px;
  }
}

.push-up-5 {
  margin-bottom: 5px;
}

/* Cookie Notice */

.cookie-notice-container p.cookie-title {
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-bottom: 5px;
}

.cookie-notice-container p {
  font-weight: 200;
  font-size: 16px;
}

.cookie-notice-container .button {
  margin-bottom: 0;
  color: #9c302e;
  background: #fff;
  border-radius: 8px;
  text-decoration: none;
  border-color: #9c302e;
}

.list-with-no-style ul li {
  list-style: none;
}

/* FAQ */
.single-accordion {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  font-size: 20px;
  color: #636464;
  padding: 50px 0;

  @include breakpoint(small) {
    width: 90%;
    font-size: 15px;
  }

  .single-row {
    padding: 20px 0 80px;
    position: relative;
    z-index: 12;

    @include breakpoint(small) {
      padding-bottom: 40px;
    }

    &.opened {
      padding-bottom: 20px;
    }



    .faq-questions {
      width: 100%;
      max-width: 880px;
      margin: 0 auto;
      background-color: #fff;
      z-index: 10;
      position: relative;
      padding: 7px;


      .gray_texture_bg {
        position: absolute;
        top: -23px;
        left: 50%;
        transform: translateX(-50%);
      }

      .blue_texture_bg_left {
        position: absolute;
        left: -21px;
        top: 50px;

        img {
          height: 110px;
        }
      }

      .blue_texture_bg_top {
        position: absolute;
        left: 42px;
        top: -23px;

        img {
          width: 160px;
        }
      }


      p {
        border: 1px solid #ccc;
        padding: 70px 50px;
        border-radius: 1px;
        margin: 0 0 0 0;

        @include breakpoint(small) {
          padding: 40px 20px;
        }

      }

      .faq-toggle-btn {
        position: absolute;
        display: block;
        bottom: -18px;
        left: 50%;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        color: #c2c2c2;
        background-color: #fff;
        border: 1px solid #c2c2c2;
        outline: none;
        transform: translateX(-50%);


        &.opened span {
          transform: rotate(180deg);
          transition: all 0.5s;
        }
      }

      .faq-toggle-btn span {
        display: block;
        transform: rotate(0);
        font-size: 20px;
        transition: all 0.5s;
        margin: auto;
      }
    }

    &:first-child {
      .faq-answers {
        display: block;
      }
    }

    .faq-answers {
      width: 85%;
      margin: 0 auto;
      display: none;
      background-color: #f1f2f2;
      position: relative;
      top: -50px;

      @include breakpoint(small) {
        width: calc(100% - 14px);
      }

      p {
        padding: 100px 50px 70px;
        margin: 0 0 0 0;

        @include breakpoint(small) {
          padding: 100px 30px 70px;

        }
      }
    }
  }

  /*single-row*/
}

/*single-accordion*/
