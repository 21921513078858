#ml-menu {
  #menu-slidebars-menu {
    padding: 40px;

    @include breakpoint(small) {
      padding: 16px;
    }

    >li {
      padding: 16px 0;
      border-bottom: 1px solid #ccc;


      >a {
        text-transform: uppercase;
        padding: 10px 0;
        font-size: 14px;

        @include breakpoint(small) {
          line-height: 16px;
          padding: 0 0;
        }

      }

      &.menu-item-has-children {
        >a {
          text-transform: uppercase;
          padding: 10px 0;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:after {
            content: "";
            display: block;
            width: 17px;
            height: 10px;
            background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/sliderbar-arrow.png) no-repeat center;
            background-size: contain;
            transform: rotate(-180deg);
            transition: transform 0.3s;
          }

          &.open {
            &:after {
              transform: rotate(0);
              transition: transform 0.3s;
            }



          }
        }
      }
    }

    a {
      color: $rhodeisland-black;
      padding: 0 0;

    }

    .sub-menu {
      font-family: open-sans, sans-serif;
      display: none;


      a {
        font-family: open-sans, sans-serif;
        font-weight: 300;
        line-height: 30px;
        font-size: 14px;
      }
    }

    .current_page_item {
      >a {
        color: #00a0c2;
        font-weight: 700;
      }
    }

  }


  .book-tour-btn {
    padding: 0 38px 40px;

    @include breakpoint(small) {
      padding: 0 16px 40px;
    }

    .long-gold-btn {
      position: relative;
      text-align: center;

      a {
        display: block;
        width: 100%;
        height: 66px;
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/long-gold-btn.png) no-repeat 50%;
        background-size: cover;
        padding: 0 0 !important;

        color: $rhodeisland-black;
        line-height: 64px;
        font-size: 18px;
        letter-spacing: 0.025em;

        @include breakpoint(small) {
          font-size: 14px;
          height: 50px;
          line-height: 50px;
        }
      }


    }
  }
}
