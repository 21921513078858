.footer {
  background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/footer-bg.png) no-repeat center top;
  background-size: cover;
  margin-top: -35px;
  position: relative;
  z-index: 1;
  color: #fff;
  padding-top: 50px;

  &::after {
    content: "";
    display: block;
    background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/footer-city.png) no-repeat center top;
    background-size: auto 100%;
    width: 100%;
    height: 70%;
    position: absolute;
    left: 0;
    bottom: 0;

  }

  a {
    color: #fff;
    display: block;
  }

  p,
  li {
    font-family: open-sans, sans-serif;
    font-size: 19px;
    line-height: 26px;
    font-weight: 300;

    @include breakpoint(medium) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .footer-sns {
    display: flex;
    justify-content: center;
    align-items: center;



    h3 {
      margin-right: 30px;
      margin-bottom: 0;
      font-size: 36px;

      @include breakpoint(small) {
        margin-right: 0;

      }
    }

    section {
      p {
        display: inline-block;
        position: relative;
        margin-right: 13px;
        margin-bottom: 0;

        span {
          min-width: 62px;
          min-height: 62px;
          text-align: center;
          line-height: 48px;
          display: block;
          background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-sns-bg.png) no-repeat center;
          background-size: cover;
          padding: 3px;

          @include breakpoint(small) {
            min-width: 52px;
            min-height: 52px;
            line-height: 36px;
            padding: 5px;
          }
        }

        @include breakpoint(small) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }

    @include breakpoint(small) {
      display: block;
      text-align: center;

      h3 {
        margin-bottom: 10px;
      }
    }
  }

  .footer-content {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 70px 0;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    z-index: 1;
    position: relative;

    @include breakpoint(small) {
      width: 80%;
      display: block;
      padding: 40px 0;
    }

    .footer-logo {
      @include breakpoint(medium) {
        display: none;
      }
    }

    .footer-addr {
      max-width: 250px;

      @include breakpoint(medium) {
        padding-right: 30px;
      }

      @include breakpoint(small) {

        margin-bottom: 30px;
      }

      .phone {
        display: flex;
        align-items: center;

        &::before {
          content: "";
          display: block;
          width: 14px;
          height: 26px;
          background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-phone.png) no-repeat left center;
          background-size: 100% 100%;
          margin-left: 5px;
          margin-right: 10px;

          @include breakpoint(small) {
            width: 10px;
            height: 20px;
          }
        }

        a {
          font-family: open-sans, sans-serif;
          font-weight: 300;
        }
      }

      .email {
        display: flex;
        align-items: center;

        &::before {
          content: "";
          display: block;
          width: 24px;
          height: 15px;
          background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-email.png) no-repeat left center;
          background-size: 100% 100%;
          margin-right: 10px;

          @include breakpoint(small) {
            width: 18px;
            height: 13px;
          }
        }

        a {
          font-family: open-sans, sans-serif;
          font-weight: 300;
        }
      }
    }

    .quick-links {
      display: flex;

      >div {
        padding-right: 50px;

        @include breakpoint(medium) {
          padding-right: 30px;
        }

        @include breakpoint(small) {
          padding-right: 15px;
        }

        &:last-child {
          @include breakpoint(small) {
            padding-right: 0;
          }
        }
      }
    }

    section:last-child {
      @include breakpoint(small) {
        display: none;

      }

      .btn-lines-wrapper {
        display: block;
        margin-bottom: 25px;

        &::before {
          width: 213px;
          height: 62px;

          @include breakpoint(medium) {
            width: 190px;
            height: 57px;
            top: -9px;
          }
        }

        a {
          @include breakpoint(medium) {
            border-radius: 25px;
            min-width: 157px;
            height: 42px;
            line-height: 40px;


            font-size: 15px;



          }
        }
      }
    }
  }

  .footer-copyright {
    padding: 30px 0;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 1;
    position: relative;

    p {
      font-size: 15px;
      margin-bottom: 0;

      @include breakpoint(small) {
        line-height: 30px;
      }

      a {
        display: inline;
      }
    }
  }
}
