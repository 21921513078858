.blog-breadcrumb {
  background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/rough-white-bg.png) no-repeat top center;
  background-size: cover;
  width: 100%;
  padding-top: 37px;
  padding-bottom: 17px;
  position: relative;
  z-index: 3;
  margin-top: -50px;
  margin-bottom: 93px;
  border-bottom: 1px solid #e1e1e1;
  color: $rhodeisland-black;
  font-family: open-sans, sans-serif;

  .breadcrumb {
    margin: 0 .625rem;

    a {
      color: $rhodeisland-black;
      font-weight: 400;
      font-family: open-sans, sans-serif;
    }

    strong {
      color: $rhodeisland-blue;
    }
  }
}

.blog-main {
  padding-right: 50px;
  padding-bottom: 150px;

  @include custom_breakpoint(780) {
    padding-right: 0;
  }

  @include breakpoint(small) {
    padding-right: 0;
  }

  .blog-title {
    font-size: 28px;
    margin-top: 50px;
    display: block;
    color: #000;
  }

  .blog-date {
    color: #28c1db;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 16px 0 30px;

    img {
      margin-right: 5px;
    }
  }

  p {
    font-size: 18px;

    @include breakpoint(medium) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  .feature-image {
    img {
      width: 100%;
    }
  }

  .blog-main-footer {

    section {
      border-top: 1px solid #ccc;

      padding: 27px 0;

      a {
        color: $rhodeisland-black;
        font-family: open-sans, sans-serif;
        font-weight: 300;
        font-size: 15px;
        transition: all 0.3s;

        &:hover {
          color: $rhodeisland-blue;

          transition: all 0.3s;
        }
      }
    }

    .blog-nav {
      text-align: center;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 16px 0;

      a {
        color: $rhodeisland-black;
        transition: all 0.3s;

        &:hover {
          color: $rhodeisland-blue;
          transition: all 0.3s;
        }

        &[rel='next'] {
          &::before {
            content: "|";
            display: inline-block;
            padding: 0 20px;
          }
        }
      }
    }

    .blog-social-share {
      strong {
        float: left;
        margin-right: 10px;
        margin-top: 3px;
      }
    }
  }

  .blog-box {
    text-align: center;
    padding: 40px;
    margin: 30px 0;
    background: #f1f2f2;
    font-style: italic;
    font-size: 20px;
    font-weight: 700;

    .btn-lines-wrapper {
      margin-top: 30px;
      font-style: normal;
    }

    .blog-social-list {
      display: flex;
      justify-content: center;
      margin-top: 27px;

      p {
        margin: 0 5px 0;

        a {
          width: 46px;
          height: 46px;
          background-color: #fff;
          border-radius: 23px;
          text-align: center;
          line-height: 46px;
          display: block;

        }
      }
    }
  }

  .blocks-gallery-grid {
    figure {
      display: block !important;

      figcaption {
        padding: 10px 0 0;
        text-align: left;
        line-height: 14px;
      }
    }

    .blocks-gallery-item {
      margin-bottom: 70px;

      .blocks-gallery-item__caption {
        background: none;
        position: relative;
        font-style: italic;
        color: $rhodeisland-black;
        display: block;
        overflow: hidden;
      }
    }

  }
}


.blog-post {
  .blog-post-image {
    position: relative;

    a {
      display: block;

      img {
        width: 100%;
      }

    }

    .blog-post-tags {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      flex-wrap: nowrap;
      background-color: #28c1db;
      border: 5px solid #fff;
      padding: 10px;
      transform: translateY(50%);

      a {
        color: #fff;
        text-transform: capitalize;

        @include breakpoint(small) {
          font-size: 12px;
        }
      }
    }
  }

  .blog-post-desc {
    .blog-desc-inner {

      .blog-title {
        font-size: 24px;
        line-height: 28px;
        text-transform: capitalize;

        @include breakpoint(medium) {
          font-size: 19px;
          line-height: 23px;
        }
      }

      .blog-date {
        margin: 10px 0 0;
        color: #28c1db;
        font-size: 12px;
        line-height: 28px;
        letter-spacing: 0.06em;
        font-family: proxima-nova, sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        img {
          padding-right: 5px;
        }

        span {

          line-height: 12px;
        }
      }


    }
  }


}

.blog-posts {
  .blog-post-inner {
    padding: 25px 10px;

    .blog-post-desc .blog-desc-inner {

      strong {
        font-size: 19px;
        line-height: 23px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      p {
        &.blog-date {
          display: flex;
          font-size: 12px;
          margin-top: 10px;

          span {
            margin-top: 3px;
          }
        }

        margin-top: 20px;
        font-size:15px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.post-pagination {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: 10px;

  .page-numbers {
    width: 36px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    color: $rhodeisland-black;
  }

  .current {
    background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/pagination-bg.png) no-repeat center;
    background-size: cover;
    color: #fff;

  }
}



.blog-sidebar {
  margin-bottom: 80px;

  .blog-social-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin-top: 0;
    }
  }

  .blog-tags-wrapper {
    a {
      color: $rhodeisland-black;

      &:hover {
        color: $rhodeisland-blue;
      }
    }
  }

  .blog-search {
    form {
      fieldset {
        position: relative;
        width: 100%;
        height: 43px;
        border: 1px solid #e1e1e1;
        border-radius: 10px;
        background: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(43%, hsla(0, 0%, 96.5%, .71)), to(rgba(3, 2, 3, .33)));
        background: linear-gradient(180deg, #fff 0, hsla(0, 0%, 96.5%, .71) 43%, rgba(3, 2, 3, .33));

        #s {
          width: calc(100% - 36px);
          left: 10px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

          &::placeholder {
            color: #464646;
          }

          /* prevent default Chrome autofill background */
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
          }

        }

        #searchsubmit {
          width: 19px;
          height: 20px;
          background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-blue-search.png) no-repeat 50%;
          background-size: contain;
          right: 10px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          color: transparent;
        }
      }

      input {
        border: none;
        background: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        position: absolute;
      }
    }
  }

  .blog-categories {
    .current-cat {
      a {
        color: $rhodeisland-blue;
        font-weight: 700;
      }
    }

    a {
      color: $rhodeisland-black;
      font-family: open-sans, sans-serif;
      font-weight: 300;
    }
  }

  .blog-archives {
    select {
      position: relative;
      width: 100%;
      height: 43px;
      border: 1px solid #e1e1e1;
      border-radius: 10px;

      background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-archieve.png) no-repeat top right, linear-gradient(180deg, #fff 0, hsla(0, 0%, 96.5%, .71) 43%, rgba(3, 2, 3, .33)), ;

    }
  }

  .blog-instagramfeed {
    p {
      margin: 5px;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 5px;
        bottom: 5px;
        background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-insta.png) no-repeat center;
        background-size: cover;
      }

      img {
        width: 100%;
      }
    }

  }

  .blog-tags {
    a {
      font-family: open-sans, sans-serif;
      font-weight: 300;
      color: $rhodeisland-black;
      &.hidden{
        display:none;
      }
      &.show-more-tags{
        font-weight:700;
        color:#28c1db;
        border-radius:5px;
        padding:5px 10px;
        max-width: 90px;
        background-color:#f1f2f2;
        border:1px solid #e1e1e1; 
    
        font-size:12px;
        display:block;
        margin:20px auto;

        &:hover{
          color:#000;
         
        }
      }
    }
  }

  .blog-sidebar-headers {
    border-bottom: 1px solid #ccc;
    margin: 36px 0 18px;
  }

}

.blog-tours {
  background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/rought-gray-bg.png) no-repeat top center;
  padding: 85px 0 100px;
  background-size: cover;

  .blog-tours-header {
    margin-bottom: 50px;
  }

  h3 {
    color: #ef4023;
    margin-bottom: 0;
    font-size: 50px;
    line-height: 58px;
    letter-spacing: .025em;

    @include breakpoint(medium) {
      font-size: 40px;
      line-height: 48px;
    }

    @include breakpoint(small) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  h2 {
    font-size: 62px;
    line-height: 57px;

    @include breakpoint(medium) {
      font-size: 54px;
      line-height: 50px;
    }

    @include breakpoint(small) {
      font-size: 42px;
      line-height: 42px;
    }
  }

  .blog-pods-footer {
    margin-top: 26px;
  }
}

.row-standard-image-pods {
  .pod {
    margin: 8px 4px;
    max-width: 506px;
    min-height: 502px;
    border: 5px solid #fff;
    position: relative;

    @include breakpoint(medium) {
      min-height: 460px;
    }

    @include breakpoint(small) {
      min-height: 420px;
    }

    .pod-image {
      margin: 0;
    }

    section {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;

      .pod-desc {
        background-color: rgba(0, 0, 0, .9);
        padding: 35px 20px;
        color: #fff;
        width: 100%;
        position: relative;
        text-align: left;

        .pod-icon {
          position: absolute;
          right: 36px;
          top: 0;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          margin: 0;
          min-height: 99px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }

        h4 {
          color: #ffcc06;
          margin: 0 0 10px;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: .02em;
          width: 80%;
        }

        h3 {
          margin: 0;
          font-size: 38px;
          line-height: 40px;
          width: 80%;
          color: #fff;

          @include breakpoint(medium) {
            font-size: 32px;
            line-height: 36px;
            width: 100%;
          }

          @include breakpoint(small) {
            font-size: 30px;
            line-height: 34px;
          }
        }

        h5 {
          color: #28c1db;
          margin: 0;
          font-size: 13px;
          line-height: 27px;
          letter-spacing: .06em;
          width: 80%;
        }

        .hidden-content {
          width: 80%;
          display: none;
        }
      }
    }

  }
}

.blog-mail {
  background-image: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/blue-bg1.png);
}

#main {
  padding-bottom: 100px;
}
