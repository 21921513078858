.wpcf7-form {
  input[type=submit] {
    outline: 0;
  }

  [type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], textarea {
    &::placeholder {
      color: #414042;
    }
  }

  input[type=text], input[type=email], select {
    width: 100%;
    border: 1px solid #acacac;
    border-radius: 10px;
    height: 52px;
    margin-bottom: 0;
    margin-top: 1rem;
    text-indent: 10px;
  }

  .custom-file-upload-wrapper {
    text-align: left;
    height: 52px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 10px;
    border: 1px solid #acacac;
    clear: both;
    background-color: #fefefe;
    position: relative;

    span.wpcf7-not-valid-tip {
      margin-top: 7px;
      font-size: 1rem;
    }

    .custom-file-upload-text {
      display: inline-block;
      padding: 0;
      padding-left: 8px;
      line-height: 52px;
      margin: 0;
      color: #414042;
      font-weight: 400;
    }

    p {
      float: right;
      margin-top: 0;
      margin-bottom: 0;
      height: 40px;

      @include breakpoint(small) {
        position: absolute;
        top: 0;
        right: 0;
      }

      .btn-custom-file-upload {
        margin: 11px 10px 0 0;
        height: 29px;
        border: 1px solid #ccc;
        font-size: 15px;
        line-height: 19px;
        min-width: 168px;
        background: linear-gradient(0deg,
            rgba(1, 136, 165, 1) 0,
            rgba(40, 193, 219, 1) 57%);
        padding: 5px 30px 0;
        color: #fff;
      }

      .show-for-sr,
      .show-on-focus {
        position: absolute !important;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }
}