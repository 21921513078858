.top-banner {
  background-color: #00a0c2;
  text-align: center;
  padding: 40px 0;
  color: #fff;
  position: relative;

  .close-top-banner {
    width: 22px;
    height: 22px;
    background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/banner-close.png)no-repeat center;
    background-size: cover;
    position: absolute;
    right: 26px;
    top: 21px;
    cursor: pointer;

    @include breakpoint(small) {
      width: 15px;
      height: 15px;
      right: 15px;
      top: 15px;
    }
  }

  section {
    width: 80%;
    margin: 0 auto;

    @include breakpoint(small) {
      width: 95%;
    }

    h2 {
      font-size: 32px;
      line-height: 48px;
      margin: 0;

      @include breakpoint(small) {
        font-size: 20px;
        line-height: 25px;
      }
    }

    p {
      font-size: 18px;
      line-height: 35px;
      margin: 0;

      strong {
        font-weight: 700;

        a {
          color: #fff;

          &:hover {
            border-bottom: 1px solid #fff;
          }
        }
      }

      @include breakpoint(small) {

        font-size: 15px;
        line-height: 23px;
      }
    }
  }
}


.header {
  width: 100%;
  padding: 20px 38px 35px;
  background: url(/wp-content/themes/tourlabs-rhodeisland/assets/images/header-bg.png) no-repeat center bottom;
  background-size: 100% 100%;
  position: relative;
  left: 0;
  top: 0;
  z-index: 100;
  margin-bottom: -32px;

  &.fixed-header-nav {
    position: fixed;
    left: 0;
    top: 0;
  }



  @include breakpoint(medium) {
    padding: 10px 38px 30px;
  }

  @include breakpoint(small) {
    padding: 10px 10px 30px;
  }

  h1 {
    position: absolute;
    left: 95px;
    top: 17px;
    max-width: 142px;
    max-height: 174px;
    width: 100%;
    height: auto;
    transition: width 0.3s;

    @include custom_breakpoint(1200) {
      left: 40px;
      max-width: 110px;
      transition: width 0.3s;
      top: 30px;
    }

    @include breakpoint(small) {
      left: 14px;
      top: 14px;
    }
  }

  .nav {
    ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0;


      &.top-nav {
        li {
          padding-left: 25px;

          a {
            color: $rhodeisland-blue;
            font-size: 14px;
            letter-spacing: 0.07em;
            font-weight: 700;

            @include custom_breakpoint(1200) {
              font-size: 12px;
            }
          }

          @include custom_breakpoint(1200) {
            padding-left: 16px;
          }
        }

        @include breakpoint(medium) {
          display: none;
        }
      }

      &.main-menu {
        margin-top: 20px;

        li {
          margin-left: 35px;

          @include custom_breakpoint(1200) {
            margin-left: 25px;
          }

          a {
            text-transform: uppercase;
            color: $rhodeisland-black;
            font-size: 17px;
            letter-spacing: 1px;
            line-height: 21px;
            font-weight: 700;

            @include custom_breakpoint(1200) {
              font-size: 15px;
            }
          }

          &:last-child {
            border: 2px solid $rhodeisland-black;
            text-align: center;
            width: 185px;
            height: 47px;
            line-height: 47px;

            @include custom_breakpoint(1200) {
              width: 145px;
              height: 47px;
            }

            a {
              &::after {
                content: "";
                display: inline-block;
                margin-left: 20px;
                width: 25px;
                height: 14px;
                background: url(/wp-content/themes/tourlabs-rhodeisland/assets/icons/icon-menu.png) no-repeat center;
                background-size: cover;

              }
            }

          }

          @include breakpoint(medium) {
            display: none;

            &:last-child {
              display: block;
            }
          }
        }
      }
    }

  }
}
