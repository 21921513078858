// ----------------------
// COLORS
// ----------------------

$rhodeisland-lightgrey: #EBEBEB;
$rhodeisland-grey: #B7B7B7;
$rhodeisland-darkgrey: #636363;
$rhodeisland-mercury: #E1E1E1;
$rhodeisland-iron: #E9E9EA;
$rhodeisland-white: #fff;
$rhodeisland-black: #000;
$rhodeisland-gold: #ffcc06;
$rhodeisland-blue: #28c1db;
$rhodeisland-darkblue: #00a0c2;
$rhodeisland-darkred: #ef4023;

$colors: (
  lightgrey: #EBEBEB,
  grey: #B7B7B7,
  darkgrey: #636363,
  mercury: #E1E1E1,
  iron: #E9E9EA,
  white: #fff,
  black: #000,
  darkred: #94161a
);


// ----------------------
// BREAKPOINTS
// ----------------------
$breakpoints: (
  small: 640px,
  medium: 1024px,
  large: 1200px
);
$breakpoint-classes: (small medium large);

@mixin breakpoint($size) {
  @if map-has-key($breakpoints, $size) {
    @media all and (max-width: map-get($breakpoints, $size)) {
      @content;
    }
  } @else {
    @warn "Unknown `#{$size}` in $breakpoints.";
  }
}
@mixin custom_breakpoint($screen) {
  @media (max-width: $screen+'px') {
      @content;
  }
}


// ------------------------------------
// LETTER-SPACING WITH CENTER ALIGN FIX
// ------------------------------------
@mixin letter-spacing-and-text-align-center($letter-spacing) { 

  // problem
  letter-spacing: $letter-spacing;  // have fun when they went 
  text-align: center;     // both of them together!!
 
  // solution                               // To fight end-of-line letter-spacing :
  padding-left: $letter-spacing * 2.5;      // move all your content to the right
}
